import React from "react";
import { Col, Row } from "react-bootstrap";
import { SiPostman, SiSlack, SiVercel, SiMacos, SiAmazonwebservices, SiWebstorm } from "react-icons/si";
import { DiDocker, DiVisualstudio } from "react-icons/di";
import { RiAmazonFill } from "react-icons/ri";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiMacos />
        <p className="tech-text">Mac OS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiVisualstudio />
        <p className="tech-text">VS Code</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWebstorm />
        <p className="tech-text">Webstorm</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <p className="tech-text">Postman</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSlack />
        <p className="tech-text">Slack</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
        <p className="tech-text">Vercel</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonwebservices />
        <p className="tech-text">AWS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDocker />
        <p className="tech-text">Docker</p>
      </Col>
    </Row>
  );
}

export default Toolstack;
