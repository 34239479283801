import React from 'react';
import {Col, Row} from 'react-bootstrap';
import { DiFirebase, DiGit, DiJavascript1, DiMongodb, DiNodejs, DiPostgresql, DiReact, DiRedis } from 'react-icons/di';
import { RiFirebaseFill, RiGithubFill, RiJavascriptFill, RiNextjsFill, RiNodejsFill, RiReactjsFill } from "react-icons/ri";


function Techstack() {
    return (
        <div>
            <Row style={{justifyContent:"center", paddingBottom:"50px"}}>
            <Col xs={4} md={2} className="tech-icons">
            <RiJavascriptFill />
            <p className="tech-text">JavaScript</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
            <RiNodejsFill />
            <p className="tech-text">Node JS</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
            <RiReactjsFill />
            <p className="tech-text">React</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
            <DiPostgresql />
            <p className="tech-text">Postgers DB</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
            <DiMongodb />
            <p className="tech-text">Mongo DB</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
            <RiNextjsFill />
            <p className="tech-text">Next JS</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
            <RiGithubFill />
            <p className="tech-text">Github</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
            <RiFirebaseFill />
            <p className="tech-text">Firebase</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
            <DiRedis />
            <p className="tech-text">Redis</p>
            </Col>
            
            </Row>
        </div>
    )
}

export default Techstack;